import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';

//Logos
import LogoTUG from '../assets/logo_ifg.png';
import LogoBoku from '../assets/logo_boku.png';
import LogoPenta from '../assets/logo_pentamap.png';
import LogoBirdlife from '../assets/logo_birdlife.png';
import LogoNHM from '../assets/logo_nhm.png';

const ProjectPage = () => {

    const partners = [
      {
        'title': 'TU Graz, Institute of Geodesy',
        'text': '',
        'people':[{'name':'Mathias Schardt'}, {'name':'Carina Sobe'}, {'name':'Florian Schöggl'}],
        'img': { src: LogoTUG, class: 'd-block mx-auto img-fluid w-50' },
        'link': 'https://www.tugraz.at'
      },
      {
        'title': 'BOKU, Institute of Zoology',
        'text': '',
        'people':[{'name':'Florian Heigl'},{'name':'Daniel Dörler'}],
        'img': { src: LogoBoku, class: 'd-block mx-auto img-fluid w-50' },
        'link': 'https://www.boku.ac.at'
      },
      {
        'title': 'pentamap mapping services OG',
        'text': '',
        'people': [{'name':'Rainer Prüller'},{'name': 'Florian Schöggl'}],
        'img': { src: LogoPenta, class: 'd-block mx-auto img-fluid w-50' },
        'link': 'https://www.pentamap.com'
      },
      {
        'title': 'Birdlife Austria',
        'text': '',
        'people': [{'name':'Norbert Teufelbauer'},{'name':'Erwin Nemeth'}],
        'img': { src: LogoBirdlife, class: 'd-block mx-auto img-fluid w-50' },
        'link': 'https://www.birdlife.at'
      },
      {
        'title': 'Natural History Museum Vienna',
        'text': '',
        'people': [{'name':'Silke Schweiger'},{'name':'Susanne Stückler'}],
        'img': { src: LogoNHM, class: 'd-block mx-auto img-fluid w-80' },
        'link': 'https://www.nhm-wien.ac.at'
      }
    ];
            
    return(
        <Container fluid>
        <h4 className="my-3">About</h4>
        <Row>
            <Col sm>
                <Card style={{ width: '100%', margin: '0.25em' }}>
                    <Card.Img variant="top" src="" />
                    <Card.Body>
                        <Card.Title>FFG ASAP 14 - AnimalProtect</Card.Title>
                        <Card.Text>
                        The project goal is to detect risk areas of wildlife accidents derived from remote sensing
                        data. Based on wild-ecological requirements, different land cover groups summarized in
                        impact corridors influencing animal behaviour will be detected. These classified impact
                        corridors will be correlated with existing accident data in a further step. From this, hazard
                        zones can be derived, indicating the danger of a wildlife accident on a scale from 1 (low
                        accident risk) to 5 (high risk of accident). These hazard zones will be made available via a
                        cloud-based service and will be demonstrated in a GNSS based demonstration application
                        for vehicle navigation.
                        </Card.Text>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
        <h4 className="my-3">Team</h4>
        <Row>
            {partners.map(partner =>
                <Col className="justify-content-md-center">
                    <Card style={{ width: '16em', height: '100%' }}>
                    <a href={partner.link} target="_blank" rel="noopener noreferrer">
                    <Card.Img className={partner.img.class} variant="top" src={partner.img.src} />
                    </a>
                    <Card.Body>
                        <Card.Title>{partner.title}</Card.Title>
                        <Card.Text>{partner.text}</Card.Text>
                        {partner.people.map(person =>
                        <li>{person.name}</li>
                        )}
                    </Card.Body>
                    </Card>
                </Col>          
            )}
        </Row>
        <h4 className="my-3">Funding</h4>
        <Row>
        <Col>
                <Card style={{ width: '100%' }}>
                <Card.Img variant="top" src="" />
                <Card.Body>
                    <Card.Title>Austrian Funding Agency</Card.Title>
                    <Card.Text>
                    
                    </Card.Text>
                </Card.Body>
                </Card>
            </Col>
        </Row>
        </Container>
    );
}
 
export default ProjectPage;