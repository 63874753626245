import React, { Component } from 'react';
import '../App.css';

// components
import ReactMap from '../components/react-map'

class MainPage extends Component {

    constructor(props){
      super(props);
      this.mapElement = React.createRef();
    }

    render() { 
      console.log('index render '+this.props.animal.name);
         return (
           <ReactMap
            testsite={this.props.testsite} 
            animal={this.props.animal}/>
         );
    }
}
 
export default MainPage;