import React, { Component } from 'react';
import ReactMapboxGl, {ZoomControl, ScaleControl, GeoJSONLayer} from 'react-mapbox-gl';

const MAPBOX_API_KEY = 'pk.eyJ1IjoicGVudGFtYXBmbG9yaWFuIiwiYSI6ImNrNm0yMWRxZDBlYXMzbXFzOGJmYW5hc2IifQ.LH1SPlbmgJ82PJh3pdJWnw';

const Map = ReactMapboxGl({
  accessToken: MAPBOX_API_KEY
});

/**
 * Style for way data/ratings
 */
const waysPaint = {
  'line-width': 4,
  'line-color':
  {
    property: 'rating',
    stops: [
    [1, 'rgba(31, 120, 180, 1)'],
    [2, 'rgba(150, 212, 221, 1)'],
    [3, 'rgba(255, 255, 255, 1)'],
    [4, 'rgba(253, 181, 193, 1)'],
    [5, 'rgba(215, 25, 28, 1)']]
  }
}

/**
 * Style for landcover data
 */
const landcoverPaint = {
  'fill-color': //'#FF0000'
  {
    property: 'subclass',
    stops: [
      [3, 'rgba(209, 255, 15, 1)'],
      [12, 'rgba(152, 230, 0, 1)'],
      [14, 'rgba(148, 207, 207, 1)'],
      [18, 'rgba(22, 211, 211, 1)'],
      [21, 'rgba(255, 211, 127, 1)'],
      [22, 'rgba(76, 0, 115, 1)'],
      [23, 'rgba(115, 0, 0, 1)'],
      [33, 'rgba(209, 255, 115, 1)'],
      [35, 'rgba(168, 168, 0, 1)'],
      [36, 'rgba(163, 255, 115, 1)'],
      [41, 'rgba(115, 178, 255, 1)'],
      [42, 'rgba(0, 38, 115, 1)'],
      [43, 'rgba(0, 92, 230, 1)'],
      [45, 'rgba(0, 168, 192, 1)'],
      [53, 'rgba(211, 255, 190, 1)'],
      [61, 'rgba(78, 78, 78, 1)'],
      [62, 'rgba(178, 178, 178, 1)'],
      [63, 'rgba(104, 104, 104, 1)'],
      [64, 'rgba(0, 0, 0, 1)'],
      [81, 'rgba(225, 225, 225, 1)'],
      [82, 'rgba(190, 255, 232, 1)'],
      [91, 'rgba(214, 38, 50, 1)'],
      [92, 'rgba(38, 115, 0, 1)'],
      [93, 'rgba(156, 156, 156, 1)']
    ]
  },
  'fill-opacity': 0.8
};

class ReactMap extends Component {

  state = {
    //Map view initial state
    center: [this.props.testsite.view.lon,this.props.testsite.view.lat],
    zoom: [this.props.testsite.view.zoom],
    showlandcover: true,
    showways: true,
    //Overlay layers
    layers: [
      //Landcover
      {
        id: 'lc_1',
        name: 'Landcover Neusiedlersee',
        bbox: '16.71,47.70,16.97,47.87',
        layertype: 'landcover',
        type: 'fill',
        data: null,
        fillLayout: { visibility:'visible'},
        fillPaint: landcoverPaint
      },
      {
        id: 'lc_2',
        name: 'Landcover Hollabrunn',
        bbox: '15.9,48.44,16.3,48.58',
        layertype: 'landcover',
        type: 'fill',
        data: null,
        fillLayout: { visibility:'visible'},
        fillPaint: landcoverPaint
      },
      {
        id: 'lc_3',
        name: 'Landcover Enns/Steyr',
        bbox: '14.53,47.55,14.73,48.05',
        layertype: 'landcover',
        type: 'fill',
        data: null,
        fillLayout: { visibility:'visible'},
        fillPaint: landcoverPaint
      },
      {
        id: 'lc_4',
        name: 'Landcover Weyer/Gaflenz',
        bbox: '14.32,47.88,14.52,48.38',
        layertype: 'landcover',
        type: 'fill',
        data: null,
        fillLayout: { visibility:'visible'},
        fillPaint: landcoverPaint
      },
      {
        id: 'lc_5',
        name: 'Landcover Neusiedlersee Amphibien',
        bbox: '16.71,47.70,16.97,47.87',
        layertype: 'landcover',
        type: 'fill',
        data: null,
        fillLayout: { visibility:'visible'},
        fillPaint: landcoverPaint
      },
      //Ways
      {
        id: 'ways_1',
        name: 'AVC Risk Neusiedlersee',
        bbox: '16.71,47.70,16.97,47.87',
        layertype: 'ways',
        type: 'line',
        data: null,
        lineLayout: { visibility:'visible'},
        linePaint: waysPaint
      },
      {
        id: 'ways_2',
        name: 'AVC Risk Hollabrunn',
        bbox: '15.9,48.44,16.3,48.58',
        layertype: 'ways',
        type: 'line',
        data: null,
        lineLayout: { visibility:'visible'},
        linePaint: waysPaint
      },
      {
        id: 'ways_3',
        name: 'AVC Risk Enns/Steyr',
        bbox: '14.53,47.55,14.73,48.05',
        layertype: 'ways',
        type: 'line',
        data: null,
        lineLayout: { visibility:'visible'},
        linePaint: waysPaint
      },
      {
        id: 'ways_4',
        name: 'AVC Risk Weyer/Gaflenz',
        bbox: '14.32,47.88,14.52,48.38',
        layertype: 'ways',
        type: 'line',
        data: null,
        lineLayout: { visibility:'visible'},
        linePaint: waysPaint
      },
      {
        id: 'ways_5',
        name: 'AVC Risk Neusiedlersee Amphibien',
        bbox: '16.71,47.70,16.97,47.87',
        layertype: 'ways',
        type: 'line',
        data: null,
        lineLayout: { visibility:'visible'},
        linePaint: waysPaint
      }
    ]
  };

  /**
   * Update layer data by layer id
   * @param {} id layer identificator
   * @param {} data layer data as JSON
   */
  onUpdateLayerData = (id, data) => {
    this.setState(state => {
      const layers = state.layers.map((layer) => {
        if(id === layer.id){
          layer.data = data;
        }
        return layer;
      });
      return {
        layers
      }
    });
  };

  /**
   * Update layer visibility by layer id
   * @param {} layertype layer type
   * @param {} data layer data as JSON
   */
  onUpdateLayerVisibility = (layertype, visibility) => {

    this.setState(state => {
      const layers = state.layers.map((layer) => {
        switch(layer.type){
          case 'fill':
            if(layertype === layer.layertype){
              layer.fillLayout = { visibility : visibility};
            }
            break;
          case 'line':
            if(layertype === layer.layertype){
              layer.lineLayout = { visibility : visibility};
            }
            break;
          default:
            return layer;
        }
        
        return layer;
      });
      return {
        layers
      }
    });
  };

  componentDidUpdate(prevProps) {
    console.log('map componentDidUpdate')

    //Testsite has changed
    if(prevProps.testsite !== this.props.testsite){
      //Fetch Landcover Data
      fetch('http://localhost:9000/landcover/'+this.props.testsite.id).then((res) => {
        return res.json();
      }).then((result) => {
        this.onUpdateLayerData('lc_'+this.props.testsite.id, result);
      })

      //Update view
      const center = [this.props.testsite.view.lon, this.props.testsite.view.lat];
      const zoom = [this.props.testsite.view.zoom]
      this.setState({center, zoom});

    }

    //Animal has changed
    if(prevProps.animal !== this.props.animal){
      //Fetch Ways Data
      fetch('http://localhost:9000/ways/'+this.props.animal.id+'/'+this.props.animal.homerange+'/'+this.props.testsite.view.bbox).then((res) => {
        return res.json();
      }).then((result) => {
        this.onUpdateLayerData('ways_'+this.props.testsite.id, result);
      })
    }
  }

  componentDidMount() {
    console.log('map componentDidMount')

    //Fetch Landcover Data
    fetch('http://localhost:9000/landcover/'+this.props.testsite.id).then((res) => {
      return res.json();
    }).then((result) => {
      this.onUpdateLayerData('lc_'+this.props.testsite.id, result);
    })

    //Fetch Ways Data
    fetch('http://localhost:9000/ways/'+this.props.animal.id+'/2000/'+this.props.testsite.view.bbox).then((res) => {
      return res.json();
    }).then((result) => {
      this.onUpdateLayerData('ways_'+this.props.testsite.id, result);
    })

  } 

  _onLayerVisibilityChange = cb => {
    console.log('onLayerChange');

    var checked;
    var visibility;
    if(cb.target.checked){
      cb.target.setAttribute('checked', true);
      checked = true;
      visibility = 'visible';
    }else{
      cb.target.removeAttribute('checked');
      checked = false;
      visibility = 'none';
    }

    switch(cb.target.id){
      case 'cb_ways':
        this.onUpdateLayerVisibility('ways', visibility);
        this.setState({showways: checked});
        break;
      case 'cb_landcover':
        this.onUpdateLayerVisibility('landcover', visibility);
        this.setState({showlandcover: checked});
        break;
    }

  }


  render() {

    console.log('render map')

    //State variablen
    const {layers, center, zoom} = this.state;

    return (
        <Map
          style="mapbox://styles/mapbox/streets-v8"
          center={center}
          zoom={zoom}
          containerStyle={{
            width:'100vw',
            height:'calc(100vh - 56px)'
          }}>
          {layers.map((layer) => (
            <GeoJSONLayer
            key={layer.id}
            {...layer}/>
          ))}
          <ZoomControl />
          <ScaleControl maxWidth={100} unit={"metric"}/>
          <div id='layercontrol'>
            <strong>Layers</strong>       
            <ul className='layerlist'>
              <li><input id="cb_ways" type="checkbox" checked={this.state.showways} onChange={this._onLayerVisibilityChange}></input><label>Roads</label></li>
              <li><input id="cb_landcover" type="checkbox" checked={this.state.showlandcover} onChange={this._onLayerVisibilityChange}></input><label>Landcover</label></li>
            </ul>
          </div>
          <div id='legend'>
            <strong>AVC Risk</strong>
            <nav className='legend clearfix'>
              <span style={{background:'rgba(31, 120, 180, 1)'}}></span>
              <span style={{background:'rgba(150, 212, 221, 1)'}}></span>
              <span style={{background:'rgba(255, 255, 255, 1)'}}></span>
              <span style={{background:'rgba(253, 181, 193, 1)'}}></span>
              <span style={{background:'rgba(215, 25, 28, 1)'}}></span>
              <label>1 (min)</label>
              <label>2</label>
              <label>3</label>
              <label>4</label>
              <label>5 (max)</label>
              <small>Source: <a href="matrix">AnimalProtect</a></small>
            </nav>
          </div>
        </Map>
    );
  }
}

export default ReactMap;