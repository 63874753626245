import React, { Component } from 'react';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import Logo from '../assets/logo_animalprotect_small_48.png'

class NavBarComponent extends Component {

    state = {  }

    constructor(props) {
        super(props);
        this.state = {
            testsites: [],
            animals: [],
        };
    }

    componentDidMount() {
        console.log('navbar componentDidMount()');

        //Testsites
        fetch("http://localhost:9000/testsites")
        .then(res => res.json())
        .then(res => {
            this.setState({testsites: res});
        })
        .catch(err => err);

        //Animals
        fetch("http://localhost:9000/animals")
        .then(res => res.json())
        .then(res => {
            this.setState({animals: res});
        })
        .catch(err => err);
    }

    render() { 

        let testsites = this.state.testsites;
        let dropdownTestsiteItems = testsites.map((testsite) =>
            <NavDropdown.Item key={testsite.name} eventKey={testsite} onSelect={(e) => this.props.onTestsiteSelected(e, testsite)}>{testsite.name}</NavDropdown.Item>
        );

        let animals = this.state.animals;
        let dropdownAnimalItems = animals.map((animal) =>
            <NavDropdown.Item key={animal.name} eventKey={animal} onSelect={(e) => this.props.onAnimalSelected(e, animal)}>{animal.name}</NavDropdown.Item>
        );

        return (  
            <>
                <Navbar collapseOnSelect className="navbar-custom" expand="lg" variant="dark">
                <Navbar.Brand href="/">
                    <img
                        alt=""
                        src={Logo}
                        width="30"
                        height="30"
                        className="d-inline-block align-top"
                    />{' '}
                    AnimalProtect
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="mr-auto">
                        <Nav.Link href="/project">Project</Nav.Link>
                        <Nav.Link href="/matrix">Matrix</Nav.Link>     
                    </Nav>
                    <Nav className="ml-auto">
                        <NavDropdown className="navbar-dropdown-custom" title={this.props.testsite.name} id="basic-nav-dropdown">
                            {dropdownTestsiteItems}
                        </NavDropdown>
                        <NavDropdown className="navbar-dropdown-custom" title={this.props.animal.name} id="basic-nav-dropdown">
                            {dropdownAnimalItems}
                        </NavDropdown>
                    </Nav>
                </Navbar.Collapse>
                </Navbar>
                </>
        );
    }
}
 
export default NavBarComponent;