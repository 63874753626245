import React from 'react';
import MatrixPDF from '../assets/AnimalProtect_Matrix.pdf';
import AllPagesPDFViewer from "../components/all-pages";


const MatrixPage = () => {
    return(
        <AllPagesPDFViewer pdf={MatrixPDF} />
    );
}
 
export default MatrixPage;