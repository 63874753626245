import React, { Component }  from 'react';
import NavBarComponent from './components/navbar';
import './App.css';

import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";

//pages
import MainPage from './pages/';
//import ReactMap from './components/react-map';
import ProjectPage from './pages/project';
import MatrixPage from './pages/matrix';
import NotFoundPage from './pages/404';

class App extends Component {

  //Initial state
  state = {
    testsite: {'id': 1, 'name': 'Neusiedlersee', 'view':{'lat': 47.785, 'lon': 16.84, 'zoom': 10, 'bbox': '16.71,47.70,16.97,47.87'}},
    animal: {'id': 11, 'name': 'Reh', 'homerange':2000}
  }

  /**
   * On testsite selected
   * @param {*} testsite 
   */
  onTestsiteSelected = (evt, testsite) => {

    var view;
    switch(testsite.id){
      //Neusiedlersee
      case 1:
      case 5:
        //16.71,47.70,16.97,47.87
        view = {'lat': 47.785, 'lon': 16.84, 'zoom': 10, 'bbox': '16.71,47.70,16.97,47.87'}
        break;
      //Hollabrunn
      case 2:
        //15.9,48.44,16.3,48.58
        view = {'lat': 48.51, 'lon': 16.1, 'zoom': 11, 'bbox': '15.9,48.44,16.3,48.58'}
        break;
      //Enns Steyr
      case 3:
        //14.53,47.55,14.73,48.05
        view = {'lat': 47.8, 'lon': 14.63, 'zoom': 10, 'bbox': '14.53,47.55,14.73,48.05'}
        break;
      //Weyer Gaflenz
      case 4:
        //14.32,47.88,14.52,48.38
        view = {'lat': 48.13, 'lon': 14.42, 'zoom': 10, 'bbox': '14.32,47.88,14.52,48.38'}
        break;
      default:
        view = {'lat': 48, 'lon': 15.6, 'zoom': 7}
    }
    testsite.view = view;

    this.setState({testsite:testsite});
    console.log('New testsite '+testsite.name+' selected' );
  }

  /**
   * On animal selected
   * @param {*} animal 
   */
  onAnimalSelected = (evt, animal) => {
    this.setState({animal:animal});
    console.log('New animal '+animal.name+' selected' );
  }

  render(){

    return (
      <React.Fragment>
        <NavBarComponent testsite={this.state.testsite} onTestsiteSelected={this.onTestsiteSelected} animal={this.state.animal} onAnimalSelected={this.onAnimalSelected} />
        <Router>
          <Switch>
            <Route exact 
              path="/" 
              render={(props) => (
                <MainPage {...props} testsite={this.state.testsite} animal={this.state.animal} />
              )}/>
            <Route exact path="/project" component={ProjectPage} />
            <Route exact path="/matrix" component={MatrixPage} />
            <Route exact path="/404" component={NotFoundPage} />
            <Redirect to="/404" />
          </Switch>
        </Router>
      </React.Fragment>
    
    );

  }
}

export default App;
